import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import { setSession } from '../utils/jwt';

import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

SuperuserGuard.propTypes = {
  children: PropTypes.node,
};

export default function SuperuserGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (user.isSuperuser || user.email === 'vaibhav.vip@cashfree.com' || user.email === 'kambhoji.subramanyam@cashfree.com') {
    return <Navigate to={'/adminDashboard'} />;
  }

  return <>{children}</>;
}
